import "./globals.css";
import {LanguageProvider, useLanguage} from "./context";
import Home from "./components/home";

function App() {

    return (
        <LanguageProvider>
            <Home/>
        </LanguageProvider>
    );
}

export default App;
